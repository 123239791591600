























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Field as RUTInput } from '../rut'
import { Field as TextInput } from '../string'
import { Field as OneOf } from '../oneOf'
import FingerprintCapture from '../fingerprintCapture/CaptureModal.vue'
import SignatureCapture from '../signature/CaptureModal.vue'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import moment from '@/plugins/moment'
import gql from 'graphql-tag'
import { DocumentFieldOptions } from '.'
import { CollectionData } from '@/models'

interface SignatureData {
  image : string
  ink : string
}

interface FingerprintData {
  image : string
  wsq : any
}

@Component({
  components: {
    RUTInput,
    TextInput,
    OneOf,
    FingerprintCapture,
    SignatureCapture
  },
  apollo: {
    clientData: {
      query: gql`query documentFromCollection($collectionId : ID, $query : JSON) {
        clientData: documentFromCollection(collectionId: $collectionId, elementId: $query)
      }`,
      variables () {
        const { collectionId, valueKey } = this.fieldOptions as DocumentFieldOptions
        return {
          collectionId,
          query: {
            [valueKey || '_id']: this.clientId
          }
        }
      },
      skip () {
        const { collectionId } = this.fieldOptions as DocumentFieldOptions
        return !collectionId || !this.clientId
      }
    }
  }
})
export default class CaptureWizard extends Vue {
  @Prop({ type: Boolean }) open !: boolean
  @Prop({ type: String, required: true }) mode !: 'sign' | 'fingerprint' | 'both'
  @Prop({ type: String }) docName !: string
  @Prop({ type: Uint8Array }) pdfBytes !: Uint8Array
  @Prop({ type: Number, required: true }) targetPage !: number
  @Prop({ type: Number, required: true }) xPos !: number
  @Prop({ type: Number, required: true }) yPos !: number
  @Prop({ type: Object, default: () => ({}) }) fieldOptions !: DocumentFieldOptions
  @Prop({ type: Object, required: true }) environmentVariables !: Record<string, any>
  @Prop({ type: String }) fieldName !: string

  currentStep = 'data'
  name = ''
  rut = ''
  fingerprint : FingerprintData | null = null
  signature : SignatureData | null = null

  clientId = ''
  clientData : any = null

  signatureModal = false
  fingerprintModal = false

  dataValid = false

  get isOpen () {
    return this.open
  }

  set isOpen (v : boolean) {
    this.$emit('update:open', v)
    if (!v) {
      this.reset()
    }
  }

  close () {
    this.isOpen = false
  }

  @Watch('open')
  reset () {
    this.name = ''
    this.rut = ''
    this.currentStep = 'data'
    this.fingerprint = null
    this.signature = null
    this.dataValid = false
    this.clientId = ''
    this.clientData = null
  }

  @Watch('clientData')
  updateClientData ({ data } : CollectionData) {
    const firstName = data[this.fieldOptions.firstNameKey!] || ''
    const lastName = data[this.fieldOptions.lastNameKey!] || ''
    this.name = `${firstName} ${lastName}`
    this.rut = data[this.fieldOptions.valueKey!] || ''
  }

  prev () {
    if (this.currentStep === 'data') return this.close()
    if (this.currentStep === 'fingerprint' && this.mode === 'both') this.currentStep = 'sign'
    else this.currentStep = 'data'
  }

  async handleSignature (signature : any) {
    this.signature = signature
    await this.next()
  }

  async handleFingerprint (fingerprint : any) {
    this.fingerprint = fingerprint
    await this.next()
  }

  async next () {
    if (!this.valid) return
    if (this.currentStep === 'data' && this.mode === 'fingerprint') {
      this.currentStep = 'fingerprint'
    } else if (this.currentStep === 'data') {
      this.currentStep = 'sign'
    } else if (this.currentStep === 'sign' && this.mode === 'both') {
      this.currentStep = 'fingerprint'
    } else {
      this.currentStep = 'inserting'
      await this.insert()
      this.$emit('done')
    }
  }

  async insert () {
    try {
      const pdfDoc = await PDFDocument.load(this.pdfBytes)
      const pages = pdfDoc.getPages()
      const targetPage = pages[this.targetPage - 1]
      const { width, height } = targetPage.getSize()
      const offset = {
        x: this.xPos * width,
        y: (1 - this.yPos) * height
      }
      // Insert signature
      if (this.signature) {
        const signatureBytes = await fetch(this.signature.image).then(r => r.arrayBuffer())
        const signatureImage = await pdfDoc.embedPng(signatureBytes)
        const signatureDims = signatureImage.scale(.25)

        targetPage.drawImage(signatureImage, {
          x: offset.x - (signatureDims.width / 2) - (this.fingerprint ? 32 : 0),
          y: offset.y - (signatureDims.height / 2) + 24,
          width: signatureDims.width,
          height: signatureDims.height
        })
        // Add offset for the fingerprint
        if (this.fingerprint) {
          offset.x += 92
        }
      }
      // Insert Fingerprint
      if (this.fingerprint) {
        const fingerprintBytes = await fetch(this.fingerprint.image).then(r => r.arrayBuffer())
        const fingerprintImage = await pdfDoc.embedPng(fingerprintBytes)
        const fingerprintDims = fingerprintImage.scale(.1)

        targetPage.drawImage(fingerprintImage, {
          x: offset.x - (fingerprintDims.width / 2),
          y: offset.y - (fingerprintDims.height / 2) + 24,
          width: fingerprintDims.width,
          height: fingerprintDims.height
        })
        // Remove offset if there's also a signature
        if (this.signature) {
          offset.x -= 92
        }
      }
      // Insert text
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

      const lines = [
        this.name.toUpperCase(),
        this.rut,
        moment().format('YYYY.MM.DD'),
        moment().format('HH:mm:ss')
      ]

      lines.forEach((line, index) => {
        targetPage.drawText(line, {
          x: offset.x - 32,
          y: offset.y - 8 - 12 * index,
          size: 9,
          color: rgb(0, 0, 1),
          font
        })
      })

      this.$emit('pdfUpdated', await pdfDoc.save())
      this.close()
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 10000
      })
      this.close()
    }
  }

  get title () {
    switch (this.currentStep) {
      case 'data':
        return '1. Datos de la ' + (this.mode === 'fingerprint' ? 'huella' : 'firma')
      case 'sign':
        return '2. Captura de Firma'
      case 'fingerprint':
        return `${this.mode === 'both' ? '3' : '2'}. Captura de Huella`
      case 'inserting':
        return `${this.mode === 'both' ? '4' : '3'}. Insertando...`
    }
  }

  get valid () {
    switch (this.currentStep) {
      case 'data':
        return this.dataValid
      case 'sign':
        return true
      case 'fingerprint':
        return true
    }
  }

  get canSkip () {
    return this.mode === 'both' && (
      (this.currentStep === 'sign') ||
      (this.signature && this.currentStep === 'fingerprint')
    )
  }

  get progress () {
    if (this.currentStep === 'data') return 25
    if (this.mode === 'both') {
      if (this.currentStep === 'sign') return 50
      if (this.currentStep === 'fingerprint') return 75
    } else if (this.currentStep !== 'inserting') {
      return 60
    } else {
      return 0
    }
  }
}
